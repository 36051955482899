import React from 'react';

import { Icons } from '@ibox/ui';

import { PRODUCT_GROUP } from '../../constants/ProductGroup';
import { getEnvironment } from '../../utils/environment';

import css from './dashboard.module.scss';

export const productGroupsDetails = [
  {
    date: 'Март 2022',
    startDate: '2022-12-01T00:00:00.000Z',
    icon: <Icons.PgPerfumery className={css.icon} />,
    id: PRODUCT_GROUP.PERFUMERY.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-12-01T00:00:00.000Z',
    icon: <Icons.PgTires className={css.icon} />,
    id: PRODUCT_GROUP.TIRES.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-03-01T00:00:00.000Z',
    icon: <Icons.PgTobacco className={css.icon} />,
    id: PRODUCT_GROUP.TOBACCO.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-03-01T00:00:00.000Z',
    icon: <Icons.PgAlcohol className={css.icon} />,
    id: PRODUCT_GROUP.ALCOHOL.code,
  },
  {
    date: 'Февраль 2023',
    startDate: '2023-02-10T00:00:00.000Z',
    icon: <Icons.PgScreen className={css.icon} />,
    id: PRODUCT_GROUP.LAPTOPS.code,
  },
  {
    date: 'Февраль 2023',
    startDate: '2023-02-10T00:00:00.000Z',
    icon: <Icons.PgTelephones className={css.icon} />,
    id: PRODUCT_GROUP.TELEPHONES.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-10-01T00:00:00.000Z',
    icon: <Icons.PgWater className={css.icon} />,
    id: PRODUCT_GROUP.WATER.code,
  },
  {
    date: 'Март 2022',
    startDate: '2022-04-01T00:00:00.000Z',
    icon: <Icons.PgBeer className={css.icon} />,
    id: PRODUCT_GROUP.BEER.code,
  },
  {
    date: 'Июнь 2020',
    startDate: '2020-06-01T00:00:00.000Z',
    icon: <Icons.PgMilk className={css.icon} />,
    id: PRODUCT_GROUP.MILK.code,
  },
  {
    date: 'Апрель 2024',
    startDate: '2024-04-15T00:00:00.000Z',
    icon: <Icons.PgChemistry className={css.icon} />,
    id: PRODUCT_GROUP.CHEMISTRY.code,
  },
  {
    date: 'Апрель 2024',
    startDate: '2024-04-15T00:00:00.000Z',
    icon: <Icons.PgConserve className={css.icon} />,
    id: PRODUCT_GROUP.CONSERVE.code,
  },
  {
    date: 'Апрель 2024',
    startDate: '2024-04-15T00:00:00.000Z',
    icon: <Icons.PgSoftDrinks className={css.icon} />,
    id: PRODUCT_GROUP.SOFTDRINKS.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgCoffeeTea className={css.icon} />,
    id: PRODUCT_GROUP.COFFEETEA.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgDyes className={css.icon} />,
    id: PRODUCT_GROUP.DYES.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgGrocery className={css.icon} />,
    id: PRODUCT_GROUP.GROCERY.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgSeaFood className={css.icon} />,
    id: PRODUCT_GROUP.SEAFOOD.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgSweets className={css.icon} />,
    id: PRODUCT_GROUP.SWEETS.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgVegetableOil className={css.icon} />,
    id: PRODUCT_GROUP.VEGETABLEOIL.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgPetfood className={css.icon} />,
    id: PRODUCT_GROUP.PETFOOD.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgAutofluids className={css.icon} />,
    id: PRODUCT_GROUP.AUTOFLUIDS.code,
  },

  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgMedicines className={css.icon} />,
    id: PRODUCT_GROUP.PHARMA.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgVetpharma className={css.icon} />,
    id: PRODUCT_GROUP.VETPHARMA.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgBio className={css.icon} />,
    id: PRODUCT_GROUP.BIO.code,
  },
  {
    date: 'Октябрь 2024',
    startDate: '2024-10-01T00:00:00.000Z',
    icon: <Icons.PgMeat className={css.icon} />,
    id: PRODUCT_GROUP.MEAT.code,
  },
].map((productGroup) => ({
  ...productGroup,
  href: getEnvironment(productGroup.id) ?? '#',
}));
