import React, { useCallback, useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { getOr } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Field, useFormState } from 'react-final-form';

import { Button, Icon, AutocompleteField } from '@ibox/ui';

import { Select } from '../../../../common_components/Form/Select';
import { withFormContext } from '../../../../common_components/Form/Form.utils';
import {
  req as required,
  checkTypesSelect,
} from '../../../../common_components/Form/Form.validations';
import { makeSelectOptions } from '../../../_Profile/Company/company-utils';
import * as selectors from '../../../_Profile/Company/ducks/Company.selectors';
import { getDisabledFields } from '../../../_Profile/Company/ducks/Company.selectors';
import { Input } from '../../../../common_components/Form/Input';
import { results } from '../../ducks/ParticipantDetails.selectors';
import { getUserInfo } from '../../../../common_components/Auth/ducks/Auth.selectors';
import { activateProductGroup } from '../../ducks/ParticipantDetails.actions';
import { UserInfo } from '../../../../common_components/Auth/ducks/Auth.store';
import { ProductGroupsAndRolesItem } from '../../../_Profile/Company/ducks/Company.store';

const name = 'productGroupsAndRoles';

type RoleAndGroupItem = {
  label: string | React.ReactNode;
  value: string;
};

type RolesCompProps = {
  formMutators: Record<string, unknown>;
  value: ProductGroupsAndRolesItem[];
  roles?: RoleAndGroupItem[];
  groups?: RoleAndGroupItem[];
  data?: Record<string, unknown>;
  disabled?: boolean;
  userInfo: UserInfo;
  activateProductGroup: (obj: Record<string, unknown>) => void;
  editMode: boolean;
  useInUOT: boolean;
};

const Roles: React.FC<RolesCompProps> = (props) => {
  const {
    formMutators,
    value = [],
    data: initialValues,
    roles,
    groups,
    disabled,
    userInfo,
    activateProductGroup,
    editMode,
    useInUOT,
  } = props;
  const { status } = initialValues;
  const { t } = useTranslation();

  const handleActivateProductGroup = (index) => {
    activateProductGroup({
      org_id: initialValues.id,
      inn: initialValues.inn,
      code: value[index].code,
      types: value[index].types.map((el) => el.value),
      license: value[index].license,
    });
  };

  const addProductGroup = useCallback(() => formMutators.push(name, {}), []);

  const removeProductGroup = useCallback(
    (index) => formMutators.remove(name, index),
    [formMutators]
  );

  const selectedProductGroups = useMemo(() => {
    return value.map((item) => item.code);
  }, [value]);

  const isProductGroupDisabled = useCallback(
    (value) => {
      return selectedProductGroups.find((item) => item === value);
    },
    [selectedProductGroups]
  );

  const productGroups = useMemo(() => {
    return groups.map((productGroup) => ({
      ...productGroup,
      ...(isProductGroupDisabled(productGroup.value)
        ? { optionDisabled: true }
        : {}),
    }));
  }, [groups, isProductGroupDisabled]);

  const isProductGroupAndRoleDisabled = useCallback(
    (productGroupAndRole) => {
      if (useInUOT) {
        return false;
      }
      if (disabled) {
        return true;
      }
      if (!productGroupAndRole.code) {
        return false;
      }
      return getOr([])('productGroupsAndRoles')(initialValues)
        .map((i) => i.code)
        .includes(productGroupAndRole.code);
    },
    [disabled, initialValues]
  );

  const canDeleteProductGroup = useCallback(
    (productGroupAndRole) =>
      !isProductGroupAndRoleDisabled(productGroupAndRole) &&
      status !== 'REGISTERED',
    [isProductGroupAndRoleDisabled]
  );

  const canCreateProductGroupAndRole = useMemo(() => {
    if (useInUOT ? !editMode : disabled) {
      return false;
    }
    return value.length < groups.length;
  }, [groups, disabled, value, editMode]);

  const isAdmin =
    userInfo.authorities.includes('ROLE_ADMIN') &&
    userInfo.authorities.includes('ROLE_ORG_IS_MP_OPERATOR');

  const { values } = useFormState();

  useEffect(() => {
    const productGroupsAndRoles = values.productGroupsAndRoles.map(
      (group: { types: string[] | { value: string; label: string }[] }) => {
        return {
          ...group,
          types: group.types
            ? group.types.reduce((acc, item) => {
                const option = roles.find(({ value }) =>
                  typeof item === 'string'
                    ? value === item
                    : value === item.value
                );
                if (option) {
                  acc.push(option);
                } else if (typeof item === 'string') {
                  formMutators.updateField('hiddenRoles', [
                    ...(values?.hiddenRoles ? values.hiddenRoles : []),
                    item,
                  ]);
                }

                return acc;
              }, [])
            : [],
        };
      }
    );
    formMutators.updateField('productGroupsAndRoles', productGroupsAndRoles);
  }, [roles, editMode]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{t('Роль участника')}</Typography>
      </Grid>
      {value.map((productGroupAndRole, index) => {
        return (
          <React.Fragment key={`${name}.${index}.code`}>
            <Grid container item spacing={2}>
              <Grid container item xs={6}>
                <Grid
                  item
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Select
                    disabled={
                      useInUOT && editMode
                        ? index < initialValues.productGroupsAndRoles.length
                        : disabled
                    }
                    label={t('Товарная группа')}
                    name={`${name}.${index}.code`}
                    options={productGroups}
                    required={editMode && !disabled}
                    validate={
                      isProductGroupAndRoleDisabled(productGroupAndRole)
                        ? undefined
                        : required
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={6}>
                <Grid
                  item
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <Field
                    fullWidth
                    multiple
                    required
                    component={AutocompleteField}
                    disabled={useInUOT ? !editMode : disabled}
                    label={t('Тип участника')}
                    name={`${name}.${index}.types`}
                    options={roles}
                    validate={(val) => checkTypesSelect(val)}
                  />
                </Grid>
                {canDeleteProductGroup(productGroupAndRole) ? (
                  <Grid
                    item
                    style={{
                      marginLeft: '20px',
                    }}
                  >
                    <Button
                      color="error"
                      disabled={value.length <= 1 || !editMode}
                      variant="outlined"
                      onClick={() => removeProductGroup(index)}
                    >
                      <Icon name="Delete" />
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <Input
                  fullWidth
                  defaultValue={disabled ? '—' : ''}
                  disabled={useInUOT ? true : disabled}
                  label={t('Лицевой счет')}
                  name={`${name}.${index}.contractId`}
                  placeholder="Лицевой счет"
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  fullWidth
                  defaultValue={disabled ? '—' : ''}
                  disabled={useInUOT ? true : disabled}
                  label={t('Статус в ТГ')}
                  name={`${name}.${index}.statusName`}
                  placeholder={t('Статус в ТГ')}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  fullWidth
                  defaultValue={disabled ? '—' : ''}
                  disabled={useInUOT ? !editMode : disabled}
                  label={t('Сведения о лицензиях')}
                  name={`${name}.${index}.license`}
                  placeholder={t('Сведения о лицензиях')}
                />
              </Grid>
              {isAdmin && productGroupAndRole.status !== '5' && (
                <Grid item xs={2}>
                  <Button
                    className="whiteColor"
                    disabled={
                      initialValues.status !== 'REGISTERED' ||
                      productGroupAndRole.status !== '0' ||
                      editMode
                    }
                    variant="contained"
                    onClick={() => handleActivateProductGroup(index)}
                  >
                    {t('Активировать ТГ')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        );
      })}

      {canCreateProductGroupAndRole ? (
        <Grid item>
          <Button variant="outlined" onClick={addProductGroup}>
            {t('Добавить ещё')}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  data: results(state),
  roles: makeSelectOptions(selectors.getCompanyRoles(state)),
  groups: makeSelectOptions(selectors.getProductGroups(state)),
  disabled: getDisabledFields(state),
  userInfo: getUserInfo(state),
});

export const RolesConnected = compose(
  connect(mapStateToProps, {
    activateProductGroup,
  }),
  withFormContext({
    mutators: true,
    name,
  })
)(Roles);
