import React from 'react';

import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { DashboardTile } from '../dashboard-tile/dashboard-tile';

import styles from './dashboard-block.module.scss';

export const DashboardBlock = ({ title, items, className }) => {
  return (
    <div className={className}>
      <Typography className={styles.title}>{title}</Typography>
      <Grid container spacing={3}>
        {items.map((item) => (
          <Grid item key={item.id} lg={4} md={6} xl={3} xs={12}>
            <DashboardTile {...item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

DashboardBlock.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};
