import React from 'react';

import { Icon } from '@ibox/ui';

enum ProductGroupIdEnum {
  ALCOHOL = 11,
  LAPTOPS = 29,
  TELEPHONES = 30,
  ANTISEPTIC = 19,
  BEER = 15,
  BICYCLE = 9,
  BIO = 17,
  ELECTRONICS = 6,
  FURS = 14,
  LP = 1,
  MEAT = 25,
  MILK = 8,
  NABEER = 22,
  NCP = 16,
  OTP = 12,
  PERFUMERY = 4,
  PETFOOD = 20,
  PHARMA = 7,
  SEAFOOD = 21,
  SHOES = 2,
  SOFTDRINKS = 23,
  TIRES = 5,
  TOBACCO = 3,
  VEGS = 24,
  WATER = 13,
  WHEELCHAIRS = 10,
  CHEMISTRY = 35,
  CONSERVE = 32,
  COFFEETEA = 47,
  DYES = 46,
  GROCERY = 37,
  SWEETS = 45,
  VEGETABLEOIL = 33,
  AUTOFLUIDS = 25,
  VETPHARMA = 26,
}

enum PRODUCT_GROUP_ENUM {
  TELEPHONES = 'TELEPHONES',
  ALCOHOL = 'ALCOHOL',
  LAPTOPS = 'LAPTOPS',
  ANTISEPTIC = 'ANTISEPTIC',
  BEER = 'BEER',
  BICYCLE = 'BICYCLE',
  BIO = 'BIO',
  ELECTRONICS = 'ELECTRONICS',
  FURS = 'FURS',
  LP = 'LP',
  MEAT = 'MEAT',
  MILK = 'MILK',
  NABEER = 'NABEER',
  NCP = 'NCP',
  OTP = 'OTP',
  PERFUMERY = 'PERFUMERY',
  PETFOOD = 'PETFOOD',
  PHARMA = 'PHARMA',
  SEAFOOD = 'SEAFOOD',
  SHOES = 'SHOES',
  SOFTDRINKS = 'SOFTDRINKS',
  TIRES = 'TIRES',
  TOBACCO = 'TOBACCO',
  VEGS = 'VEGS',
  WATER = 'WATER',
  WHEELCHAIRS = 'WHEELCHAIRS',
  CONSERVE = 'CONSERVE',
  CHEMISTRY = 'CHEMISTRY',
  COFFEETEA = 'COFFEETEA',
  DYES = 'DYES',
  GROCERY = 'GROCERY',
  SWEETS = 'SWEETS',
  VEGETABLEOIL = 'VEGETABLEOIL',
  VETPHARMA = 'VETPHARMA',
  AUTOFLUIDS = 'AUTOFLUIDS',
}

enum ProductGroupCodeEnum {
  ALCOHOL = 'alcohol',
  LAPTOPS = 'laptops',
  ANTISEPTIC = 'antiseptic',
  TELEPHONES = 'telephones',
  BEER = 'beer',
  BICYCLE = 'bicycle',
  BIO = 'bio',
  ELECTRONICS = 'electronics',
  FURS = 'furs',
  LP = 'lp',
  MEAT = 'meat',
  MILK = 'milk',
  NABEER = 'nabeer',
  NCP = 'ncp',
  OTP = 'otp',
  PERFUMERY = 'perfumery',
  PETFOOD = 'petfood',
  PHARMA = 'pharma',
  SEAFOOD = 'seafood',
  SHOES = 'shoes',
  SOFTDRINKS = 'softdrinks',
  TIRES = 'tires',
  TOBACCO = 'tobacco',
  VEGS = 'vegs',
  WATER = 'water',
  WHEELCHAIRS = 'wheelchairs',
  CHEMISTRY = 'chemistry',
  CONSERVE = 'conserve',
  COFFEETEA = 'coffeetea',
  DYES = 'dyes',
  GROCERY = 'grocery',
  SWEETS = 'sweets',
  VEGETABLEOIL = 'vegetableoil',
  VETPHARMA = 'vetpharma',
  AUTOFLUIDS = 'autofluids',
}

class ProductGroup {
  constructor(options: ProductGroup) {
    Object.assign(this, options);
  }
}

export const PRODUCT_GROUP = {
  [PRODUCT_GROUP_ENUM.ALCOHOL]: new ProductGroup({
    id: ProductGroupIdEnum.ALCOHOL,
    code: ProductGroupCodeEnum.ALCOHOL,
    name: 'Алкогольные товары',
    env: 'REACT_APP_PG_ALCOHOL_URL',
    icon: <Icon name="PgAlcohol" />,
  }),
  [PRODUCT_GROUP_ENUM.LAPTOPS]: new ProductGroup({
    id: ProductGroupIdEnum.LAPTOPS,
    code: ProductGroupCodeEnum.LAPTOPS,
    name: 'Ноутбуки',
    env: 'REACT_APP_PG_LAPTOPS_URL',
    icon: <Icon name="PgScreen" />,
  }),
  [PRODUCT_GROUP_ENUM.TELEPHONES]: new ProductGroup({
    id: ProductGroupIdEnum.TELEPHONES,
    code: ProductGroupCodeEnum.TELEPHONES,
    name: 'Телефонные аппараты',
    env: 'REACT_APP_PG_TELEPHONES_URL',
    icon: <Icon name="PgTelephones" />,
  }),
  [PRODUCT_GROUP_ENUM.BEER]: new ProductGroup({
    id: ProductGroupIdEnum.BEER,
    code: ProductGroupCodeEnum.BEER,
    name: 'Пиво, напитки, изготавливаемые на основе пива, и слабоалкогольные напитки',
    env: 'REACT_APP_PG_BEER_URL',
    icon: <Icon name="PgBeer" />,
  }),
  [PRODUCT_GROUP_ENUM.BICYCLE]: new ProductGroup({
    id: ProductGroupIdEnum.BICYCLE,
    code: ProductGroupCodeEnum.BICYCLE,
    name: 'Велосипеды и велосипедные рамы',
    env: 'REACT_APP_PG_BICYCLE_URL',
    icon: <Icon name="Bicycle" />,
  }),
  [PRODUCT_GROUP_ENUM.ELECTRONICS]: new ProductGroup({
    id: ProductGroupIdEnum.ELECTRONICS,
    code: ProductGroupCodeEnum.ELECTRONICS,
    name: 'Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки',
    env: 'REACT_APP_PG_ELECTRONICS_URL',
    icon: <Icon name="Electronics" />,
  }),
  [PRODUCT_GROUP_ENUM.FURS]: new ProductGroup({
    id: ProductGroupIdEnum.FURS,
    code: ProductGroupCodeEnum.FURS,
    name: 'Товары из натурального меха',
    env: 'REACT_APP_PG_FURS_URL',
    icon: <Icon name="Furs" />,
  }),
  [PRODUCT_GROUP_ENUM.LP]: new ProductGroup({
    id: ProductGroupIdEnum.LP,
    code: ProductGroupCodeEnum.LP,
    name: 'Предметы одежды, белье постельное, столовое, туалетное и кухонное',
    env: 'REACT_APP_PG_CLOTHES_URL',
    icon: <Icon name="PgClothes" />,
  }),
  [PRODUCT_GROUP_ENUM.MILK]: new ProductGroup({
    id: ProductGroupIdEnum.MILK,
    code: ProductGroupCodeEnum.MILK,
    name: 'Молочная продукция',
    env: 'REACT_APP_PG_MILK_URL',
    icon: <Icon name="PgMilk" />,
  }),
  [PRODUCT_GROUP_ENUM.NCP]: new ProductGroup({
    id: ProductGroupIdEnum.NCP,
    code: ProductGroupCodeEnum.NCP,
    name: 'Никотиносодержащая продукция',
    env: 'REACT_APP_PG_NCP_URL',
    icon: <Icon name="Ncp" />,
  }),
  [PRODUCT_GROUP_ENUM.OTP]: new ProductGroup({
    id: ProductGroupIdEnum.OTP,
    code: ProductGroupCodeEnum.OTP,
    name: 'Альтернативная табачная продукция',
    env: 'REACT_APP_PG_OTP_URL',
    icon: <Icon name="TobaccoAlternative" />,
  }),
  [PRODUCT_GROUP_ENUM.PERFUMERY]: new ProductGroup({
    id: ProductGroupIdEnum.PERFUMERY,
    code: ProductGroupCodeEnum.PERFUMERY,
    name: 'Духи и туалетная вода',
    env: 'REACT_APP_PG_PERFUMERY_URL',
    icon: <Icon name="PgPerfumery" />,
  }),
  [PRODUCT_GROUP_ENUM.PHARMA]: new ProductGroup({
    id: ProductGroupIdEnum.PHARMA,
    code: ProductGroupCodeEnum.PHARMA,
    name: 'Лекарственные препараты для медицинского применения',
    env: 'REACT_APP_PG_PHARMA_URL',
    icon: <Icon name="PgMedicines" />,
  }),
  [PRODUCT_GROUP_ENUM.SHOES]: new ProductGroup({
    id: ProductGroupIdEnum.SHOES,
    code: ProductGroupCodeEnum.SHOES,
    name: 'Обувные товары',
    env: 'REACT_APP_PG_SHOES_URL',
    icon: <Icon name="PgShoes" />,
  }),
  [PRODUCT_GROUP_ENUM.TIRES]: new ProductGroup({
    id: ProductGroupIdEnum.TIRES,
    code: ProductGroupCodeEnum.TIRES,
    name: 'Шины и покрышки пневматические резиновые новые',
    env: 'REACT_APP_PG_TIRES_URL',
    icon: <Icon name="Tires" />,
  }),
  [PRODUCT_GROUP_ENUM.TOBACCO]: new ProductGroup({
    id: ProductGroupIdEnum.TOBACCO,
    code: ProductGroupCodeEnum.TOBACCO,
    name: 'Табачная продукция',
    env: 'REACT_APP_PG_TOBACCO_URL',
    icon: <Icon name="PgTobacco" />,
  }),
  [PRODUCT_GROUP_ENUM.WATER]: new ProductGroup({
    id: ProductGroupIdEnum.WATER,
    code: ProductGroupCodeEnum.WATER,
    name: 'Упакованная вода',
    env: 'REACT_APP_PG_WATER_URL',
    icon: <Icon name="PgWater" />,
  }),
  [PRODUCT_GROUP_ENUM.WHEELCHAIRS]: new ProductGroup({
    id: ProductGroupIdEnum.WHEELCHAIRS,
    code: ProductGroupCodeEnum.WHEELCHAIRS,
    name: 'Медицинские изделия',
    env: 'REACT_APP_PG_WHEELCHAIRS_URL',
    icon: <Icon name="Wheelchairs" />,
  }),

  [PRODUCT_GROUP_ENUM.ANTISEPTIC]: new ProductGroup({
    id: ProductGroupIdEnum.ANTISEPTIC,
    code: ProductGroupCodeEnum.ANTISEPTIC,
    name: 'Антисептики и дезинфицирующие средства',
    env: 'REACT_APP_PG_ANTISEPTIC_URL',
    icon: <Icon name="PgAntiseptic" />,
  }),
  [PRODUCT_GROUP_ENUM.PETFOOD]: new ProductGroup({
    id: ProductGroupIdEnum.PETFOOD,
    code: ProductGroupCodeEnum.PETFOOD,
    name: 'Корма для домашних животных (кроме сельскохозяйственных)',
    env: 'REACT_APP_PG_PETFOOD_URL',
    icon: <Icon name="PgPetfood" />,
  }),
  [PRODUCT_GROUP_ENUM.SEAFOOD]: new ProductGroup({
    id: ProductGroupIdEnum.SEAFOOD,
    code: ProductGroupCodeEnum.SEAFOOD,
    name: 'Морепродукты',
    env: 'REACT_APP_PG_SEAFOOD_URL',
    icon: <Icon name="PgSeaFood" />,
  }),
  [PRODUCT_GROUP_ENUM.NABEER]: new ProductGroup({
    id: ProductGroupIdEnum.NABEER,
    code: ProductGroupCodeEnum.NABEER,
    name: 'Безалкогольное пиво',
    env: 'REACT_APP_PG_NABEER_URL',
    icon: <Icon name="PgBeer" />,
  }),
  [PRODUCT_GROUP_ENUM.SOFTDRINKS]: new ProductGroup({
    id: ProductGroupIdEnum.SOFTDRINKS,
    code: ProductGroupCodeEnum.SOFTDRINKS,
    name: 'Соковая продукция и безалкогольные напитки',
    env: 'REACT_APP_PG_SOFTDRINKS_URL',
    icon: <Icon name="PgSoftDrinks" />,
  }),
  [PRODUCT_GROUP_ENUM.VEGS]: new ProductGroup({
    id: ProductGroupIdEnum.VEGS,
    code: ProductGroupCodeEnum.VEGS,
    name: 'Плодоовощная продукция',
    env: 'REACT_APP_PG_VEGS_URL',
    icon: <Icon name="PgVegs" />,
  }),
  [PRODUCT_GROUP_ENUM.MEAT]: new ProductGroup({
    id: ProductGroupIdEnum.MEAT,
    code: ProductGroupCodeEnum.MEAT,
    name: 'Мясо',
    env: 'REACT_APP_PG_MEAT_URL',
    icon: <Icon name="PgMeat" />,
  }),
  [PRODUCT_GROUP_ENUM.CHEMISTRY]: new ProductGroup({
    id: ProductGroupIdEnum.CHEMISTRY,
    code: ProductGroupCodeEnum.CHEMISTRY,
    name: 'Парфюмерные и косметические средства и бытовая химия\t',
    env: 'REACT_APP_PG_CHEMISTRY_URL',
    icon: <Icon name="PgChemistry" />,
  }),
  [PRODUCT_GROUP_ENUM.CONSERVE]: new ProductGroup({
    id: ProductGroupIdEnum.CONSERVE,
    code: ProductGroupCodeEnum.CONSERVE,
    name: 'Консервированная продукция',
    env: 'REACT_APP_PG_CONSERVE_URL',
    icon: <Icon name="PgConserve" />,
  }),
  [PRODUCT_GROUP_ENUM.COFFEETEA]: new ProductGroup({
    id: ProductGroupIdEnum.COFFEETEA,
    code: ProductGroupCodeEnum.COFFEETEA,
    name: 'Кофе, чай и их заменители',
    env: 'REACT_APP_PG_COFFEETEA_URL',
    icon: <Icon name="PgCoffeeTea" />,
  }),
  [PRODUCT_GROUP_ENUM.DYES]: new ProductGroup({
    id: ProductGroupIdEnum.DYES,
    code: ProductGroupCodeEnum.DYES,
    name: 'Красители, пигменты и лаки',
    env: 'REACT_APP_PG_DYES_URL',
    icon: <Icon name="PgDyes" />,
  }),
  [PRODUCT_GROUP_ENUM.GROCERY]: new ProductGroup({
    id: ProductGroupIdEnum.GROCERY,
    code: ProductGroupCodeEnum.GROCERY,
    name: 'Бакалейная продукция',
    env: 'REACT_APP_PG_GROCERY_URL',
    icon: <Icon name="PgGrocery" />,
  }),
  [PRODUCT_GROUP_ENUM.SWEETS]: new ProductGroup({
    id: ProductGroupIdEnum.SWEETS,
    code: ProductGroupCodeEnum.SWEETS,
    name: 'Шоколад и готовые пищевые продукты, содержащие какао',
    env: 'REACT_APP_PG_SWEETS_URL',
    icon: <Icon name="PgSweets" />,
  }),
  [PRODUCT_GROUP_ENUM.VEGETABLEOIL]: new ProductGroup({
    id: ProductGroupIdEnum.VEGETABLEOIL,
    code: ProductGroupCodeEnum.VEGETABLEOIL,
    name: 'Растительные и животные масла и жиры',
    env: 'REACT_APP_PG_VEGETABLEOIL_URL',
    icon: <Icon name="PgVegetableOil" />,
  }),
  [PRODUCT_GROUP_ENUM.AUTOFLUIDS]: new ProductGroup({
    id: ProductGroupIdEnum.AUTOFLUIDS,
    code: ProductGroupCodeEnum.AUTOFLUIDS,
    name: 'Масла смазочные и жидкости технические',
    env: 'REACT_APP_PG_AUTOFLUIDS_URL',
    icon: <Icon name="PgAutofluids" />,
  }),
  [PRODUCT_GROUP_ENUM.VETPHARMA]: new ProductGroup({
    id: ProductGroupIdEnum.VETPHARMA,
    code: ProductGroupCodeEnum.VETPHARMA,
    name: 'Ветеринарные препараты',
    icon: <Icon name="PgVetpharma" />,
  }),
  [PRODUCT_GROUP_ENUM.BIO]: new ProductGroup({
    id: ProductGroupIdEnum.BIO,
    code: ProductGroupCodeEnum.BIO,
    name: 'Биологически активные добавки к пище',
    env: 'REACT_APP_PG_BIO_URL',
    icon: <Icon name="PgBio" />,
  }),
};
