import React, { useMemo } from 'react';

import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getAvailableProductGroups } from '../_Profile/Company/ducks/Company.selectors';
import {
  getProductGroupsDetails,
  getUserInfo,
} from '../../common_components/Auth/ducks/Auth.selectors';

import { productGroupsDetails } from './dashboard.data';
import { DashboardBlock } from './dashboard-block/dashboard-block';

import styles from './dashboard.module.scss';

const getFilteredPg = (items, status) => {
  return items.filter(
    ({ productGroupStatus }) => productGroupStatus === status
  );
};

const DashboardComp = ({ productGroups, pgInProfile, userInfo }) => {
  const { t } = useTranslation();

  const { showDashboard, authorities } = userInfo;

  const hasAccess = authorities.some(
    (auth) =>
      auth === 'ROLE_ADMIN' || auth === 'ROLE_USER' || auth === 'ROLE_ORG_OGV'
  );

  const items = useMemo(() => {
    const pgIds = pgInProfile.map((item) => item.name);
    const sortedPg = hasAccess
      ? productGroups
      : productGroups.filter((item) => [...pgIds].includes(item.id));

    const allPg = productGroupsDetails
      .map((productGroupDetail) => {
        const productGroup = _.find(sortedPg, {
          id: productGroupDetail.id,
        });

        return {
          ...productGroupDetail,
          name: _.get(productGroup, 'name'),
          status: _.get(productGroup, 'status'),
          productGroupStatus: _.get(productGroup, 'productGroupStatus'),
          disabled: _.get(productGroup, 'disabled', true),
        };
      })
      .filter((productGroup) => !!productGroup.productGroupStatus);

    const activePg = allPg
      .filter((pg) => !pg.disabled)
      .sort((a, b) => (a.startDate <= b.startDate ? -1 : 1));

    const disabledPg = allPg
      .filter((pg) => pg.disabled)
      .sort((a, b) => (a.startDate <= b.startDate ? -1 : 1));

    return _.concat(activePg, disabledPg);
  }, [pgInProfile, hasAccess, productGroups]);

  const commercialPg = useMemo(
    () => getFilteredPg(items, 'COMMERCIAL'),
    [items]
  );

  const experimentalPg = useMemo(
    () => getFilteredPg(items, 'EXPERIMENT'),
    [items]
  );

  if (!showDashboard) {
    return null;
  }

  return (
    <div className={styles.container}>
      <DashboardBlock
        className={styles.commercialItems}
        items={commercialPg}
        title={t('Маркировка')}
      />
      <DashboardBlock
        className={styles.experimentalItems}
        items={experimentalPg}
        title={t('Пилотные проекты')}
      />
    </div>
  );
};

DashboardComp.propTypes = {
  productGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  productGroups: getAvailableProductGroups(state),
  pgInProfile: getProductGroupsDetails(state),
  userInfo: getUserInfo(state),
});

export const Dashboard = connect(mapStateToProps)(DashboardComp);
