import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';

import { Icons } from '@ibox/ui';

import { translate } from '../../Translate/Translate';

import { ProductGroupIcon } from './product-group-icon';

import classes from './DashboardTile.module.scss';

export const DashboardTile = ({
  description,
  disabled,
  icon,
  className,
  name,
}) => {
  return (
    <div className={classes.wrapper}>
      <div
        className={clsx(
          classes.root,
          {
            [classes.disabled]: disabled,
          },
          className
        )}
      >
        <div className={classes.head}>
          {icon ? (
            <ProductGroupIcon className={classes.icon}>{icon}</ProductGroupIcon>
          ) : null}
          <Typography className={classes.label}>{translate(name)}</Typography>
        </div>
      </div>

      {Boolean(description) && (
        <Tooltip
          arrow
          className={classes.description}
          placement="right"
          title={description}
        >
          <Icons.Information height="24" width="24" />
        </Tooltip>
      )}
    </div>
  );
};

DashboardTile.propTypes = {
  description: PropTypes.element,
  icon: PropTypes.element,
  name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool.isRequired,
};
