export const ProductGroupEnum = Object.freeze({
  ALCOHOL: 11,
  BEER: 15,
  BICYCLE: 9,
  ELECTRONICS: 6,
  FURS: 14,
  LP: 1,
  MILK: 8,
  NCP: 16,
  OTP: 12,
  PERFUMERY: 4,
  SHOES: 2,
  TIRES: 5,
  TOBACCO: 3,
  WATER: 13,
  WHEELCHAIRS: 10,
  LAPTOPS: 29,
  TELEPHONES: 30,
  CONSERVE: 32,
  CHEMISTRY: 35,
  SOFTDRINKS: 23,
  SEAFOOD: 21,
  COFFEETEA: 47,
  DYES: 46,
  GROCERY: 37,
  SWEETS: 45,
  VEGETABLEOIL: 33,
  PETFOOD: 20,
  AUTOFLUIDS: 43,
  PHARMA: 7,
  VETPHARMA: 26,
  BIO: 17,
  MEAT: 25,
});
